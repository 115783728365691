import { useAuditStore } from '@/store/audit.store';
import API from '@/composables/API';

export default async function getAuditReportTemplate(reportId) {
  try {
    if (isNaN(reportId)) {
      throw new Error('Invalid report ID');
    }

    const auditStore = useAuditStore();
    const report = await auditStore.loadAuditReport(reportId);

    const complianceId = report.data.audit_report[0].complaince_id;
    const complianceResponse = await API.get(`api/compliance/get-by-id?Compliance_id=${complianceId}`);

    const regDId = complianceResponse.data.data.compliance.RegD_id;
    const templatesResponse = await API.get(`api/audit/get-organization-auditreport-templates?RegD_id=${regDId}`);

    if (templatesResponse.status === 200 && templatesResponse.data?.length) {
      const template = templatesResponse.data.filter(_ => _.Compliance_id == complianceId)?.pop();
      return template;
    } else {
      throw new Error('Template not found');
    }
  } catch (error) {
    console.error('Error fetching audit report template:', error);
    return null;
  }
}