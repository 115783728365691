import axios from "axios";
const apiBaseURL = process.env.VUE_APP_API_PATH;
const TOKEN = localStorage.getItem("user_access_token") || null;
import useToast from "@/global_components/Toast/useToast";

class API {
  async request(
    route,
    payload = null,
    method = "GET",
    contentType = "application/json",
    returnResponse = false
  ) {
    let options = {
      method: method,
      headers: {
        Accept: "application/json",
        "x-access-token": `${TOKEN}`,
        "Device-Type": "web",
      },
    };

    if (contentType == "application/json") {
      options.headers["Content-Type"] = contentType;
    }

    //payload will be sent as form data if content type is multipart/form-data
    if (options.method !== "GET") {
      if (contentType.toLowerCase() == "multipart/form-data") {
        options.data = convertToFormData(payload);
      } else if (payload && typeof payload === "object") {
        options.data = JSON.stringify(payload);
      }
    } else if (payload) {
      // payload will be appended to the url  if method is GET
      route = this.appendParams(route, payload);
    }
    options.url = this.url(route);

    try {
      let response = await axios(options);
      return response;
    } catch (error) {
      // return error.response.data
      if (error.response?.data?.message === "Failed to authenticate token.") {
        // useToast(error.response.data.message)
        localStorage.clear();
        window.open("/login", "_self");
      } else {
        if (returnResponse) {
          return error.response;
        }
        return error.response?.data;
      }
    }
  }

  async get(route, payload = null) {
    return await this.request(route, payload, "GET");
  }

  async post(route, payload = null, returnResponse = false) {
    return await this.request(
      route,
      payload,
      "post",
      "application/json",
      returnResponse
    );
  }

  async put(route, payload = null, returnResponse = false) {
    return await this.request(
      route,
      payload,
      "PUT",
      "application/json",
      returnResponse
    );
  }

  async delete(route, payload = null, returnResponse = false) {
    return await this.request(
      route,
      payload,
      "DELETE",
      "application/json",
      returnResponse
    );
  }

  async patch(route, payload = null, returnResponse = false) {
    return await this.request(
      route,
      payload,
      "PATCH",
      "application/json",
      returnResponse
    );
  }

  async upload(route, payload = null) {
    return await this.request(route, payload, "POST", "multipart/form-data");
  }

  async formData(route, payload = null) {
    return await this.request(route, payload, "POST", "multipart/form-data");
  }

  appendParams(route, payload) {
    let url = new URL(this.url(route));
    let params = new URLSearchParams(url.search.slice(1));

    if (payload && typeof payload === "object") {
      for (let key in payload) {
        params.append(key, payload[key]);
      }
    }
    route = route.split("?")[0] + "?" + params.toString();
    return route;
  }

  url(route) {
    return `${apiBaseURL}${
      apiBaseURL && apiBaseURL.slice(apiBaseURL.length - 1) == "/" ? "" : "/"
    }${route}`;
  }
  customConvertToFormData(data) {
    const formData = new FormData();

    data.forEach((item, index) => {
      if (item !== null) {
        for (const key in item) {
          if (key === "answer" && typeof item[key] === "object") {
            for (const subKey in item[key]) {
              if (Array.isArray(item[key][subKey])) {
                item[key][subKey].forEach((subItem, subIndex) => {
                  formData.append(
                    `data[${index}][${key}][${subKey}][${subIndex}]`,
                    subItem
                  );
                });
              } else {
                formData.append(
                  `data[${index}][${key}][${subKey}]`,
                  item[key][subKey]
                );
              }
            }
          } else if (key === "file" && Array.isArray(item[key])) {
            item[key].forEach((file, fileIndex) => {
              formData.append(`data[${index}][${key}][${fileIndex}]`, file);
            });
          } else {
            formData.append(`data[${index}][${key}]`, item[key]);
          }
        }
      }
    });

    return formData;
  }

}

function convertToFormData(payload) {
  const formData = new FormData();
  for (const key in payload) {
    if (Array.isArray(payload[key])) {
      for (let i = 0; i < payload[key].length; i++) {
        formData.append(key + "[]", payload[key][i]);
      }
    } else formData.append(key, payload[key]);
  }
  return formData;
}

export default new API();
