import { defineStore } from "pinia";
import Api from "@/APi/axios_instance";
import router from "@/router/routes";
import showToast from "@/global_components/Toast/useToast";
import { Account } from "@/store/SwitchAccount";
import supplierMenuAccess from "@/assets/supplier-menu.json";
import organizationMenuAccess from "@/assets/organization.json";
import auditFirmMenuAccess from "@/assets/audit-firm-menu.json";
export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    access_token: localStorage.getItem("user_access_token"),
    isLoggedIn: localStorage.getItem("isLoggedIn"),
    user: JSON.parse(localStorage.getItem("UserInfo")),
    error_message: null,
    loader: false,
    account: JSON.parse(localStorage.getItem("Account")),
    header_manu: JSON.parse(localStorage.getItem("menuAccess") || null),
    UserRole: JSON.parse(localStorage.getItem("User_role") || null),
    term_condition: null,
  }),
  actions: {
    logout() {
      localStorage.clear();
      this.access_token = "";
      this.account = null;
      this.user = null;
      this.UserRole = null;
      this.isLoggedIn = false;
      this.error_message = null;
      this.loader = false;
      this.$router.push({ name: "login" });
      location.reload();
    },
    filterHeaderMenu(data) {
      let supplierFilterList = ["Compliances", "Add Certificate"];
      let AuditFirmFilterList = ["Compliances", "Add Certificates"];

      if (this.account[0].Account_type === 1) {
        data = data.filter(
          (item) => !supplierFilterList.includes(item.Menue_name)
        );
      } else if (this.account[0].Account_type === 3) {
        data = data.filter(
          (item) => !AuditFirmFilterList.includes(item.Menue_name)
        );
      }

      if (process.env.VUE_APP_USER_MODULE == "false") {
        data = data.filter(
          (item) =>
            !["User Access", "departments", "roles"].includes(item.Menue_name)
        );
      }

      return data;
    },

    async login(data, value = true) {
      this.error_message = null;
      this.loader = true;
      try {
        const result = await Api.post("/api/User/Login", data);
        if (result.message == "Network Error") {
          return false;
        }
        if (result.data?.User?.twoway_auth) {
          localStorage.setItem("otp", JSON.stringify(result));
          router.push({
            name: "otp",
            params: { email: result.data?.User?.Email },
          });
          return result;
        } else {
          const access_token = result?.data?.token;
          const UserInfo = result?.data?.User;
          const account = result?.data?.accoutns;
          const user_role = result?.data?.userrole;
          const sessionexpiry = result?.data?.sessionExpiry;
          if (value) {
            const SwitchAccount = account?.filter((data) => {
              return data.RegD_id == UserInfo.RegD_id;
            });
            localStorage.setItem(
              "switch_account",
              JSON.stringify(SwitchAccount)
            );
          }
          localStorage.setItem("user_access_token", access_token);
          localStorage.setItem("UserInfo", JSON.stringify(UserInfo));
          localStorage.setItem("Account", JSON.stringify(account));
          localStorage.setItem("User_role", JSON.stringify(user_role));
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("expirytime", sessionexpiry);
          this.access_token = access_token;
          this.isLoggedIn = true;
          this.account = account;
          this.user = UserInfo;
          this.UserRole = user_role;
          return result;
        }
      } catch (error) {
        const message = error?.response?.data?.method;
        this.error_message = message;
        this.loader = false;

        if (message) {
          showToast(message);
        } else {
          if (typeof error.response?.data === 'string') {
            showToast(error.response.data);
          }
        }
        return error;
      }
    },
    async switchProfile(payload, value = true) {
      try {
        const result = await Api.post("/api/profile/switch-profile", {
          account_type: payload.account_type,
          attached_id: payload.attached_id,
        });

        const access_token = result?.data?.token;
        const UserInfo = result?.data?.User;
        const account = result?.data?.accoutns;
        const user_role = result?.data?.userrole;
        const sessionexpiry = result?.data?.sessionExpiry;
        if (value) {
          const SwitchAccount = account?.filter((data) => {
            return data.RegD_id == UserInfo.RegD_id;
          });
          localStorage.setItem("switch_account", JSON.stringify(SwitchAccount));
        }
        localStorage.setItem("user_access_token", access_token);
        localStorage.setItem("UserInfo", JSON.stringify(UserInfo));
        localStorage.setItem("Account", JSON.stringify(account));
        localStorage.setItem("User_role", JSON.stringify(user_role));
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("expirytime", sessionexpiry);
        this.access_token = access_token;
        this.isLoggedIn = true;
        this.account = account;
        this.user = UserInfo;
        this.UserRole = user_role;
        return result;
        // }
      } catch (error) {
        console.log(error);
      }
    },
    async loginViaOTP(data) {
      this.loader = true;
      const res = await Api.get(
        `/api/User/TwoFactorVerification?email=${data.email}&otp=${data.otp}`
      );
      if (res.message == "Network Error") {
        return false;
      }
      if (res.data == "Verified") {
        const result = JSON.parse(localStorage.getItem("otp"));
        const access_token = result?.data?.token;
        const UserInfo = result?.data?.User;
        const account = result?.data?.accoutns;
        const user_role = result?.data?.userrole;
        const sessionexpiry = result?.data?.sessionExpiry;
        if (true) {
          const SwitchAccount = account?.filter((data) => {
            return data.RegD_id == UserInfo.RegD_id;
          });
          localStorage.setItem("switch_account", JSON.stringify(SwitchAccount));
        }
        localStorage.setItem("user_access_token", access_token);
        localStorage.setItem("UserInfo", JSON.stringify(UserInfo));
        localStorage.setItem("Account", JSON.stringify(account));
        localStorage.setItem("User_role", JSON.stringify(user_role));
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("expirytime", sessionexpiry);
        this.access_token = access_token;
        this.isLoggedIn = true;
        this.account = account;
        this.user = UserInfo;
        this.UserRole = user_role;
        localStorage.removeItem("otp");
        return res;
      } else showToast(res.data);
    },
    async loginwithSocial(data, value = true) {
      this.error_message = null;
      this.loader = true;
      try {
        const result = await Api.post("/api/User/LoginWithSocialMedia", data);
        const access_token = result?.data?.token;
        const UserInfo = result?.data?.User;
        const account = result?.data?.accoutns;
        const user_role = result?.data?.userrole;
        const sessionexpiry = result?.data?.sessionExpiry;
        if (value) {
          const SwitchAccount = account?.filter((data) => {
            return data.RegD_id == UserInfo.RegD_id;
          });
          localStorage.setItem("switch_account", JSON.stringify(SwitchAccount));
          // localStorage.setItem('socket','wss://apis.glasshub.ai/websockets?RegD_id='+SwitchAccount[0]?.RegD_id+'&Account_Type='+SwitchAccount[0]?.Account_type+'&Category_ids='+UserInfo?.cat_ids)
        }
        localStorage.setItem("user_access_token", access_token);
        localStorage.setItem("UserInfo", JSON.stringify(UserInfo));
        localStorage.setItem("Account", JSON.stringify(account));
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("User_role", JSON.stringify(user_role));
        localStorage.setItem("expirytime", sessionexpiry);
        this.access_token = access_token;
        this.isLoggedIn = true;
        this.account = account;
        this.user = UserInfo;
        this.UserRole = user_role;
        window.location.href = "dashboard";
        // this.$router.push({ name: 'dashboard' });
      } catch (error) {
        // const message = error?.response?.data?.method
        // this.error_message=message;
        this.loader = false;
        showToast(
          "Account not match and this social media login only for freelancer"
        );
      }
    },

    async Manu() {
      let staticPath = [
        //   {
        //     "Access_id": 11367,
        //     "Role_id": 38,
        //     "Menue_id": 35,
        //     "Top_menue_id": 0,
        //     "RegM_id": "31",
        //     "RegD_id": "38",
        //     "Menue_name": "Audits",
        //     "Menue_label": "Audits",
        //     "Menue_location": "#",
        //     "Isparent": true,
        //     "Sorting_order": 3,
        //     "quick_link": null,
        //     "Is_search": true,
        //     "title": null
        // },
        {
          Access_id: 11359,
          Role_id: 38,
          Menue_id: 39,
          Top_menue_id: 35,
          Menue_name: "All Audits",
          Menue_label: "All Audits",
          Menue_location: "allorgaudit_reports",
          Isparent: false,
          Sorting_order: 4,
          quick_link: null,
          Is_search: true,
          title: "All Audits",
        },
        // {
        //   Top_menue_id:13,
        //   Menue_name: "Suppliers Certificates Status",
        //   Menue_label: "Suppliers Certificates Status",
        //   Menue_location: "suppliers_certicates_status",
        //   Isparent: false,
        //   Sorting_order: 4,
        //   title: "Suppliers Certificates Statuss"
        // },
        {
          Access_id: 11373,
          Role_id: 38,
          Menue_id: 36,
          Top_menue_id: 35,
          Menue_name: "Re Audited",
          Menue_label: "Re Audited",
          created_at: "2023-07-16T09:27:53.000Z",
          updated_at: "2023-07-16T09:27:53.000Z",
          Menue_location: "reaudorgaudit_reports",
          Isparent: false,
          Sorting_order: 1,
          quick_link: null,
          Is_search: true,
          title: "Re-Audited",
        },
        {
          Access_id: 11358,
          Role_id: 38,
          Menue_id: 38,
          Top_menue_id: 35,
          RegM_id: "31",
          RegD_id: "38",
          Menue_name: "Completed",
          Menue_label: "Completed",
          created_at: "2023-07-16T09:27:53.000Z",
          updated_at: "2023-07-16T09:27:53.000Z",
          Menue_location: "comorgaudit_reports",
          Isparent: false,
          Sorting_order: 3,
          quick_link: null,
          Is_search: true,
          title: "Completed",
        },
        {
          Access_id: 11357,
          Role_id: 38,
          Menue_id: 37,
          Top_menue_id: 35,
          Menue_name: "In Process",
          Menue_label: "In Process",
          created_at: "2023-07-16T09:27:53.000Z",
          updated_at: "2023-07-16T09:27:53.000Z",
          Menue_location: "Inprocessorgaudit_reports",
          Isparent: false,
          Sorting_order: 2,
          quick_link: null,
          Is_search: true,
          title: "In Process",
        },
      ];
      const switch_account = Account();
      let Account_type = switch_account.Account_detail[0].Account_type;
      if (Account_type === 0) {
        let links = this.filterHeaderMenu(organizationMenuAccess);
        this.header_manu = [...links, ...staticPath];
      } else if (Account_type === 1) {
        this.header_manu = this.filterHeaderMenu(supplierMenuAccess);
      } else if (Account_type === 3) {
        this.header_manu = this.filterHeaderMenu(auditFirmMenuAccess);
      } else {
        let response = await Api.get(
          `/api/menueaccess/get-by-role-id?RegD_id=${switch_account.User_role[0].RegD_id}&User_id=${switch_account.User_role[0].User_id}`
        );
        if (response.status == 200) {
          this.header_manu = this.filterHeaderMenu(response.data);
        }
      }
      localStorage.setItem("menuAccess", JSON.stringify(this.header_manu));
    },

    async FatchTerm(data) {
      try {
        this.loader = true;
        let resp = await Api.get(
          `/api/userlegal/latest-legal?Account_id=${data?.acc_id}&Page_Name=${data?.page_id}`
        );
        this.loader = false;
        this.term_condition = null;
        this.term_condition = resp.data[0]?.Description;
      } catch (error) {
        showToast(error.response.data);
      }
    },
    async user_profile() {
      await Api.get("/api/profile/profile-by-id?User_id=" + this.user.User_id)
        .then((response) => {
          let data = {
            User_email: response.data[0].User_email,
            User_password: response.data[0].User_password,
          };
          this.login(data, false);
        })
        .catch((error) => {
          showToast(error.response.data);
        });
    },
  },
});
